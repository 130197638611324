body {
  overflow-y: scroll;
}

img {
  max-width: 100%;
}

.player {
  float: left;
  width: 165px;
  margin: 8px;
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
